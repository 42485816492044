import {
  resetChannelFilter,
  resetEPGDate,
  setChannelFilter,
  setChannelSort,
  setDirectArrayForFavorites,
  setEPGDate,
  setLoginShow,
} from "actions/ui";
import classnames from "classnames";
import AnimatedList from "components/AnimatedList";
import EPGGridImage from "components/EPGGridImage";
import EPGGridText from "components/EPGGridText";
import EPGHeaderItem from "components/EPGHeaderItem";
import EpgHeaderSelector from "components/EpgHeaderSelector";
import Header from "components/Header";
import FavoriteAdd from "components/Icons/FavoriteAdd";
import FavoriteRemove from "components/Icons/FavoriteRemove";
import Live from "components/Icons/Live";
import Loader from "components/Loader";
import ModalNPVRRecord from "components/ModalNPVRRecord";
import consts from "consts/consts";
import { ResponsiveType } from "consts/responsive";
import {
  addDays,
  differenceInCalendarDays,
  isPast,
  isSameDay,
  setHours,
  subDays,
} from "date-fns";
import { getFromLocal } from "helpers/localStorage";
import jwt_decode from "jwt-decode";
import debounce from "lodash.debounce";
import React, { PureComponent } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { CellMeasurer, CellMeasurerCache } from "react-virtualized";
import { compose } from "redux";
import { TucanoActions, TucanoModels, TucanoSelectors } from "web-api/main";

import styles from "./style.module.css";

let channelRef = [];
class PageEPG extends PureComponent {
  headerGrid = React.createRef();
  bodyGrid = React.createRef();
  menuContextRef = React.createRef();
  menuContextFilterRef = React.createRef();
  columnRefs = [];
  cache = [];
  activeChannel = undefined;

  constructor(args) {
    super(args);
    this.now = new Date().getTime();
    this.shouldResetEPG = true;
    this.menuContextRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      hover_id: null,
      hover: false,
      isScrolling: false,
      scrollLeft: 0,
      clientX: 0,
      oldClientX: 0,
      clientY: 0,
      oldClientY: 0,
      menuContextOpen: false,
      menuContext: {},
      resizing: false,
      favoriteGroup: [],
      sortedGroups: [],
      token: null,
      isSortOpen: false,
      inputFocus: false,
      inputValue: "",
      open: false,
      cache: [],
      items: [],
      hasMore: true,
      channelsPerPage: 10,
      currentPage: 0,
    };
  }

  setRef(el, channelId) {
    this.columnRefs[channelId] = el;
  }

  async componentDidMount() {
    const { t, isConnected, groups, profileToken, dispatch } = this.props;
    dispatch(resetChannelFilter());
    if (isConnected && profileToken) {
      const decoded = jwt_decode(profileToken);
      this.setState({ token: decoded.id_profile });
    }
    document.title = `${t(this.props.route.title)} - ${consts.name}`;
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
    }
    if (consts.epgMetaData.length > 0) {
      document.getElementsByTagName("meta")[3].content =
        consts.epgMetaData[0].content;
      document.getElementsByTagName("meta")[4].content =
        consts.epgMetaData[1].content;
      document.getElementsByTagName("meta")[5].content =
        consts.epgMetaData[2].content;
    }
    if (consts.appModules.epg !== true) {
      this.props.history.replace("/");
    }

    const languageId = getFromLocal("languageId") ?? consts.languageId;

    dispatch(TucanoActions.getChannels(undefined, undefined, languageId)).then(
      async (_result) => {
        const { EPGDate } = this.props;
        if (consts.appModules.is_nPVR) {
          await dispatch(TucanoActions.getNPVRRecords());
        }
        await dispatch(TucanoActions.getEPG(EPGDate, languageId, true));
      }
    );

    window.addEventListener(
      "resize",
      debounce(this.handleResize.bind(this), 1000),
      false
    );
    this.updateEPGTime(this.now, false);
    // if (!isConnected) {
    //   await this.props.dispatch(setChannelFilter({ value: null, profile: this.state.token }, isConnected));
    //   await this.props.dispatch(setDirectArrayForFavorites(null));
    // }
    await this.initialiseChannels(groups);
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  initialiseChannels = async (groups) => {
    const { isConnected, channelFavorits, channels } = this.props;
    //kill me please: find a user's favorite channels and build a group that we add to sorting groups
    let favoriteGroup = [];
    channelFavorits?.map((favChan) => {
      channels?.forEach((channel) => {
        if (channel.id == favChan.idChannel) favoriteGroup.push(channel);
      });
    });
    if (
      this.props.filter !== "Favorites" ||
      !isConnected ||
      this.props.filter == null
    ) {
      await this.props.dispatch(setDirectArrayForFavorites(null));
    }
    // add favorite category to premade groups then resort
    let sortedGroups = groups;
    if (isConnected) {
      if (this.props.filter == "Favorites") {
        await this.props.dispatch(setDirectArrayForFavorites(favoriteGroup));
      }
    }
    sortedGroups = sortedGroups?.sort(function (a, b) {
      var textA = a?.group?.toUpperCase();
      var textB = b?.group?.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    await this.setState({
      favoriteGroup: favoriteGroup,
      sortedGroups: sortedGroups,
    });
  };
  handleApplyFilter = async (filter) => {
    const { groups, isConnected } = this.props;
    // if (filter == "Favorites") {
    //   this.props.dispatch(setDirectArrayForFavorites(this.state.favoriteGroup));
    // } else {
    if (filter.label !== "All channels") {
      this.props.dispatch(
        setChannelFilter(
          { value: filter.label, profile: this.state.token },
          isConnected
        )
      );
    } else {
      await this.props.dispatch(
        setChannelFilter(
          { value: null, profile: this.state.token },
          isConnected
        )
      );
    }
    await this.props.dispatch(setDirectArrayForFavorites(null));
    // }
    await this.initialiseChannels(groups);
    this.setState(
      {
        hasMore: true,
        items: [],
        currentPage: 0,
      },
      () => {
        this.loadMore(true);
      }
    );
  };
  handleApplySort = async (item) => {
    const { isConnected } = this.props;
    await this.props.dispatch(
      setChannelSort({ value: item, profile: this.state.token }, isConnected)
    );
    this.setState(
      {
        hasMore: true,
        items: [],
        currentPage: 0,
      },
      () => {
        this.loadMore(true);
      }
    );
  };
  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (this.props.channelFavorits !== prevProps.channelFavorits) {
      const favoritesIndex = this.props.groups?.findIndex(
        (group) => group.group === "Favorites"
      );
      const updatedGroups = [...this.props.groups];
      updatedGroups[favoritesIndex] = {
        ...updatedGroups[favoritesIndex],
        count: this.props?.channelFavorits?.length,
      };
      this.setState({ sortedGroups: updatedGroups });
    }
    const languageId = getFromLocal("languageId") ?? consts.languageId;
    if (
      !prevProps.filteredChannels &&
      this.props.filteredChannels?.sortedChannels &&
      this.props.filteredChannels.sortedChannels.length > 0
    ) {
      this.loadMore(true);
      this.setState({
        cache: [],
      });
      Object.values(this.props.filteredChannels.sortedChannels).forEach(
        (channel) => {
          this.setState((prevState) => {
            let prevCache = prevState.cache;
            prevCache[channel.id] = new CellMeasurerCache({
              fixedWidth: true,
              defaultHeight: 140,
            });

            return { cache: prevCache };
          });
        }
      );
      return true;
    }

    if (
      prevProps.filteredChannels &&
      prevProps.filteredChannels.sortedChannels &&
      this.props.filteredChannels &&
      this.props.filteredChannels.sortedChannels &&
      ((JSON.stringify(prevProps.filteredChannels.sortedChannels) !==
        JSON.stringify(this.props.filteredChannels.sortedChannels) &&
        this.props.filteredChannels.sortedChannels.length > 0) ||
        this.state.cache.length === 0)
    ) {
      this.handleResize();
      this.setState({
        _cache: [],
      });
      Object.values(this.props.filteredChannels.sortedChannels).forEach(
        (channel) => {
          this.setState((prevState) => {
            let prevCache = prevState.cache;
            prevCache[channel.id] = new CellMeasurerCache({
              fixedWidth: true,
              defaultHeight: 140,
            });
            return { cache: prevCache };
          });
        }
      );
      this.refreshPrograms();
    }

    if (!isSameDay(new Date(this.props.EPGDate), new Date(prevProps.EPGDate))) {
      const { EPGDate } = this.props;
      this.props.dispatch(TucanoActions.getEPG(EPGDate, languageId, true));
    }
  }

  componentWillUnmount() {
    const { isConnected } = this.props;
    if (!isConnected) {
      this.props.dispatch(
        setChannelSort({ value: null, profile: null }, isConnected)
      );
      this.props.dispatch(
        setChannelFilter({ value: null, profile: null }, isConnected)
      );
    }
    window.removeEventListener(
      "resize",
      debounce(this.handleResize.bind(this), 1000),
      false
    );

    if (this.shouldResetEPG) {
      this.props.dispatch(resetEPGDate());
      this.props.dispatch(TucanoActions.resetEPG());
    }
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    event.stopPropagation();
    if (
      this.menuContextRef &&
      !this.menuContextRef.current?.contains(event.target)
    ) {
      this.setState({ menuContextOpen: false });
    }
  }

  handleResize() {
    this.setState({ resizing: true });

    if (this.props.filteredChannels) {
      Object.values(this.props.filteredChannels.sortedChannels).forEach(
        (channel) => {
          if (
            this.columnRefs &&
            this.columnRefs[channel.id] &&
            this.columnRefs[channel.id].listRef
          ) {
            this.setState((prevState) => {
              let prevCache = prevState.cache;
              prevCache[channel.id].clearAll();
              return { cache: prevCache };
            });

            // I comment this ligne because he fixed go to current epg live now when i click record Button
            // this.columnRefs[channel.id].listRef.current.recomputeRowHeights();
          }
        }
      );
    }
    setTimeout(() => {
      this.setState({ resizing: false });
    }, 1000);
  }

  handleClickOnClose() {
    if (this.props.viewport.type !== "DESKTOP" && this.props.onClick) {
      // if ( this.props.onClick) {
      this.props.onClick();
    }
    this.setState({
      inputValue: "",
      inputFocus: false,
    });
  }
  handleMouseMove = (e, channelId) => {
    const { clientX, clientY, scrollLeft, isScrolling } = this.state;

    if (isScrolling) {
      // SCROLL TOP
      let positionTop = 0;
      if (channelId) {
        if (this.columnRefs[channelId] && this.columnRefs[channelId].state) {
          positionTop = parseInt(
            this.columnRefs[channelId].state.scrollTop + clientY - e.clientY
          );
        }

        if (positionTop < 0) {
          positionTop = 0;
        }

        this.columnRefs[channelId].state.scrollTop = positionTop;
      }

      // SCROLL LEFT
      let positionLeft = scrollLeft + clientX - e.clientX;

      if (positionLeft < 0) {
        positionLeft = 0;
      }

      const headerGridColumnWidth = this.headerGrid.current.props.columnWidth;
      const headerGridColumnCount = this.headerGrid.current.props.columnCount;
      const headerGridWidth = this.headerGrid.current.props.width;
      if (
        positionLeft <
        headerGridColumnCount * headerGridColumnWidth - headerGridWidth
      ) {
        this.bodyGrid.current.scrollLeft = positionLeft;

        this.setState({
          ...this.state,
          scrollLeft: positionLeft,
          clientX: e.clientX,
          clientY: e.clientY,
        });
      }
    }
  };

  handleMouseLeaveCell = (_e) => {
    this.setState({ hover_id: null, hover: false });
  };

  handleMouseUp = () => {
    this.setState({ ...this.state, isScrolling: false });
  };

  handleMouseDown = (e) => {
    // only left mouse button
    if (e.button !== 0) return;
    this.setState({
      ...this.state,
      isScrolling: true,
      clientX: e.clientX,
      clientY: e.clientY,
      oldClientX: e.clientX,
      oldClientY: e.clientY,
    });
    e.stopPropagation();
    e.preventDefault();
    return false;
  };

  handleScroll = () => {
    const scrollLeft = this.bodyGrid.current.scrollLeft;
    this.setState({ ...this.state, scrollLeft: scrollLeft });
    if (
      this.bodyGrid.current.scrollWidth -
        this.bodyGrid.current.scrollLeft -
        this.bodyGrid.current.offsetWidth <=
      350
    ) {
      this.loadMore();
    }
  };

  loadMore = (initialisePagination = false) => {
    if (!this.state.hasMore && !initialisePagination) return;
    const { currentPage, channelsPerPage } = this.state;
    const page = initialisePagination ? 0 : currentPage;
    const { filteredChannels } = this.props;
    const offset = channelsPerPage * page;
    const totalPages = Math.ceil(
      filteredChannels?.sortedChannels?.length / channelsPerPage
    );
    const paginatedItems =
      filteredChannels?.sortedChannels?.slice(
        offset,
        channelsPerPage * (page + 1)
      ) || [];
    this.setState((prevState) => ({
      cache: [],
      items: initialisePagination
        ? [...paginatedItems]
        : [...prevState.items, ...paginatedItems],
      currentPage: page + 1,
      hasMore: totalPages > page + 1 ? true : false,
    }));

    if (this.props?.filteredChannels?.sortedChannels) {
      Object.values(this.props.filteredChannels.sortedChannels).forEach(
        (channel) => {
          this.setState((prevState) => {
            let prevCache = prevState.cache;
            prevCache[channel.id] = new CellMeasurerCache({
              fixedWidth: true,
              defaultHeight: 140,
            });

            return { cache: prevCache };
          });
        }
      );
    }
  };

  refreshPrograms = () => {
    const { currentPage, channelsPerPage } = this.state;
    const { filteredChannels } = this.props;
    const paginatedItems =
      filteredChannels?.sortedChannels?.slice(
        0,
        channelsPerPage * currentPage
      ) || [];
    this.setState({
      items: [...paginatedItems],
    });
  };

  async updateEPGTime(date, resetActiveChannel = true) {
    if (
      date &&
      new Date(date) &&
      date.toString().length >
        9 /* this last codition is to prevent updating the epg date with a realy old date */
    ) {
      if (resetActiveChannel) {
        this.activeChannel = undefined;
      }
      await this.props.dispatch(setEPGDate(new Date(date)));
      this.setState(
        {
          hasMore: true,
          items: [],
          currentPage: 0,
        },
        () => {
          this.loadMore(true);
        }
      );
    }
  }

  findEPGScrollPosition(channel) {
    if (this.state.resizing || this.activeChannel === channel.id) {
      return -1;
    }

    let position = -1;
    const refTime = this.props.EPGDate;
    let closest = Infinity;

    if (channel) {
      Object.entries(channel?.getEPGs()).forEach(([key, item]) => {
        if (
          item &&
          item.getStartTime().getTime() <= refTime &&
          (refTime < item.getEndTime().getTime() || closest)
        ) {
          closest = item.getStartTime().getTime();
          position = key;
        }
      });
    }
    return parseInt(position);
  }

  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  openMenuContext(channel, clientX, clientY) {
    this.setState({
      menuContextOpen: !this.state.menuContextOpen,
      menuContext: { channel: channel, clientX: clientX, clientY: clientY },
    });
  }

  handleScrollRight = () => {
    //
  };
  handleArrow = (direction) => {
    const { scrollLeft } = this.state;
    const { filteredChannels } = this.props;
    const headerGridColumnWidth = this.columnWidth;
    const headerGridColumnCount =
      filteredChannels && filteredChannels.sortedChannels
        ? filteredChannels.sortedChannels.length
        : 0;
    const headerGridWidth = this.width;
    let positionLeft = scrollLeft;
    if (direction === "right") {
      if (
        scrollLeft + headerGridColumnWidth <
        headerGridColumnCount * headerGridColumnWidth - headerGridWidth
      ) {
        positionLeft = scrollLeft + headerGridColumnWidth;
      } else {
        positionLeft =
          headerGridColumnCount * headerGridColumnWidth - headerGridWidth;
      }
    } else if (direction === "left") {
      if (scrollLeft > 0) {
        positionLeft = scrollLeft - headerGridColumnWidth;
      }
    }
    this.bodyGrid.current.scrollLeft = positionLeft;
    this.setState({ scrollLeft: positionLeft });
  };
  handleScrollLeft = () => {
    //
  };
  isChannelFavorite = (id) => {
    const { channelFavorits } = this.props;
    const found =
      channelFavorits &&
      channelFavorits.find((channel) => channel.idChannel === id);
    return found !== undefined;
  };
  AddToFavorite = async (id) => {
    const { dispatch, isConnected, groups } = this.props;

    let channelFavorits = this.props.channelFavorits;
    const isChannelFavorite = this.isChannelFavorite(id);
    if (isConnected) {
      if (isChannelFavorite) {
        const newChannelList = channelFavorits.filter(
          (channel) => channel.idChannel !== id
        );
        await dispatch(TucanoActions.addFavoriteChannel(newChannelList));
      } else {
        let order;
        if (channelFavorits.length > 0) {
          order = channelFavorits[channelFavorits.length - 1].order + 1;
        } else {
          order = 1;
        }
        channelFavorits.push({ idChannel: id, order: order });
        groups?.push({ group: "Favorites", count: channelFavorits.length });
        await this.initialiseChannels(groups);
        await dispatch(TucanoActions.addFavoriteChannel(channelFavorits));
      }
    } else {
      this.props.dispatch(setLoginShow(true));
      // this.props.history.push("?login=1");
    }
  };

  renderRow(
    channel,
    {
      index, // Index of row
      _isScrolling, // The List is currently being scrolled
      _isVisible, // This row is visible within the List (eg it is not an overscanned row)
      key, // Unique key within array of rendered rows
      parent, // Reference to the parent List (instance)
      style, // Style object to be applied to row (to position it);
      // This must be passed through to the rendered row element.
    }
  ) {
    const { viewport, isConnected, t } = this.props;

    const epgItem = channel.getEPGs()[index];

    let url = "";
    const now = new Date().getTime();
    if (epgItem) {
      url = consts.routes.epgAsset.url.replace(":id", epgItem.getEpgId());
      if (
        channel.isCatchupChannel === true &&
        epgItem.getStartTime().getTime() > now - channel.getCatchupValue() &&
        epgItem.isCatchupAvailable() &&
        isPast(epgItem.getEndTime())
      ) {
        url = consts.routes.movieAsset.url.replace(
          ":id",
          epgItem.isCatchupAvailable()
        );
      }
    }

    const width = parent.props.width - 5;
    const height = Math.round((width + 7.11) / (16 / 9));
    // const width = 250;
    // const height = 140;
    const minHeight = viewport.type !== ResponsiveType.DESKTOP ? 89 : 140;

    const showNextDay =
      differenceInCalendarDays(new Date(this.props.EPGDate), new Date()) <
      consts.futurNumberDaysEPG;
    const showPreviousDay =
      differenceInCalendarDays(new Date(), new Date(this.props.EPGDate)) <
      consts.pastNumberDaysEPG;

    return (
      <div
        key={key}
        onMouseDown={(mouseDownEvent) => this.handleMouseDown(mouseDownEvent)}
        onMouseMove={(mouseMoveEvent) =>
          this.handleMouseMove(mouseMoveEvent, channel.id)
        }
        onMouseUp={() => this.handleMouseUp()}
        onScroll={this.handleScroll}
        style={{ ...style }}
      >
        <CellMeasurer
          key={key}
          parent={parent}
          rowIndex={index}
          columnIndex={0}
          cache={this.state.cache[channel.id]}
        >
          <div
            key={key}
            style={{
              width: width,
              paddingTop:
                index === 0 || channel.getEPGs().length === index
                  ? "0px"
                  : "5px",
            }}
          >
            <>
              {index === 0 && showPreviousDay && (
                <div
                  style={{
                    position: "relative",
                    textDecoration: "none",
                    overflow: "hidden",
                    height: "60px",
                    paddingBottom: "5px",
                    cursor: "pointer",
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    this.updateEPGTime(
                      subDays(setHours(this.props.EPGDate, 23), 1)
                    );
                  }}
                >
                  <div
                    className={styles.backgroundGridTextEPG}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      width: width,
                    }}
                  >
                    <Trans t={t}>Previous day</Trans>
                  </div>
                </div>
              )}
              <div
                onMouseLeave={() => this.handleMouseLeaveCell()}
                onMouseEnter={() =>
                  this.setState({
                    hover_id: channel.id + "_" + key,
                    hover: true,
                  })
                }
              >
                {channel.getEPGs().length === 0 && (
                  <div
                    className={styles.backgroundGridTextEPG}
                    style={{
                      height:
                        parent.props.height -
                        (showNextDay ? 65 : 0) -
                        (showPreviousDay
                          ? 65
                          : 0) /* Previous + Next day + padding 5px*/,
                      width: width,
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    {/* Should use a proper class or component for No information available message */}
                    <Trans t={t}>No information available</Trans>
                  </div>
                )}
                {channel.getEPGs().length > 0 &&
                  url &&
                  epgItem instanceof TucanoModels.EPG && (
                    <>
                      {epgItem.getDisplayImage(
                        consts.durationEPGDisplayImage
                      ) === true && (
                        <div
                          style={{
                            position: "relative",
                            height: height,
                            textDecoration: "none",
                            overflow: "hidden",
                          }}
                        >
                          <EPGGridImage
                            epg={epgItem}
                            width={width}
                            height={height}
                            channel={channel}
                            hover={
                              this.state.hover &&
                              this.state.hover_id === channel.id + "_" + key
                            }
                            subscriptionModal={this.subscriptionModal.bind(
                              this
                            )}
                            isConnected={isConnected}
                            onClick={(event, url) => {
                              event.stopPropagation();
                              event.preventDefault();
                              const {
                                oldClientX,
                                oldClientY,
                                clientX,
                                clientY,
                              } = this.state;
                              if (
                                oldClientX === clientX &&
                                oldClientY === clientY
                              ) {
                                /* WARNING : Dirty hack
                                 * When clicking an EPG, we don't reset EPG date to keep previous data and selected date */
                                this.shouldResetEPG = false;
                                this.props.history.push(url);
                              }
                            }}
                          />
                        </div>
                      )}
                      <div
                        style={{
                          position: "relative",
                          height: minHeight,
                          textDecoration: "none",
                          overflow: "hidden",
                        }}
                      >
                        <EPGGridText
                          refreshPrograms={this.refreshPrograms.bind(this)}
                          rootClassName={classnames(
                            styles.backgroundGridTextEPG,
                            {
                              [styles.backgroundGridTextEPGHover]:
                                this.state.hover &&
                                this.state.hover_id === channel.id + "_" + key,
                            }
                          )}
                          epg={epgItem}
                          channel={channel}
                          isConnected={isConnected}
                          onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            const { oldClientX, oldClientY, clientX, clientY } =
                              this.state;
                            if (
                              oldClientX === clientX &&
                              oldClientY === clientY
                            ) {
                              /* WARNING : Dirty hack
                               * When clicking an EPG, we don't reset EPG date to keep previous data and selected date */
                              this.shouldResetEPG = false;
                              this.props.history.push(url);
                            }
                          }}
                        />
                      </div>
                    </>
                  )}
              </div>
              {(index === channel.getEPGs().length - 1 ||
                channel.getEPGs().length === 0) && (
                <div
                  style={{
                    position: "relative",
                    textDecoration: "none",
                    overflow: "hidden",
                  }}
                >
                  {showNextDay && (
                    <div
                      style={{
                        cursor: "pointer",
                        paddingTop: "5px",
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        this.updateEPGTime(
                          addDays(setHours(this.props.EPGDate, 0), 1)
                        );
                      }}
                    >
                      <div
                        className={styles.backgroundGridTextEPG}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "60px",
                          width: width,
                        }}
                      >
                        <Trans t={t}>Next day</Trans>
                      </div>
                    </div>
                  )}
                  {channel.getEPGs().length > 0 && (
                    <div
                      style={{
                        paddingTop: "5px",
                      }}
                    >
                      <div
                        className={styles.emptySpace}
                        style={{
                          height:
                            parent.props.height -
                            (epgItem &&
                            epgItem.getDisplayImage(
                              consts.durationEPGDisplayImage
                            ) === true
                              ? height
                              : 0) -
                            minHeight -
                            (showNextDay ? 75 : 0),
                        }}
                      ></div>
                    </div>
                  )}
                </div>
              )}
            </>
          </div>
        </CellMeasurer>
      </div>
    );
  }
  gotoElement = async (channelName) => {
    if (
      channelRef &&
      channelRef[channelName] === null &&
      channelRef[channelName] !== "skip"
    ) {
      if (channelRef && channelRef[channelName]) {
        channelRef[channelName] = "skip";
      }
    }
    if (
      channelRef &&
      channelRef[channelName] === undefined &&
      channelRef[channelName] !== "skip"
    ) {
      do {
        await this.handleScrollLeft();
      } while (channelRef && channelRef[channelName] === undefined);
      if (channelRef && channelRef[channelName]) {
        channelRef[channelName] = "skip";
      }
    }
  };

  render() {
    const { isEPGLoading, isMenuOpen, viewport, EPGDate, modalNPVRInfo, t } =
      this.props;
    const { menuContextOpen, menuContext, items, sortedGroups } = this.state;
    /*
    DESKTOP HEIGHT CALCULATE = 277.76 = 127.76 + 70 + 50 + 30
      Title Header Height = 127.76 (height=48, padding-top=10, padding-bottom=10, margin-top=29.880, margin-bottom=29.880)
      EPG Header Selector Height = 70 (height=40, padding-bottom=30)
      EPG Header Channel Height = 50 (height=50)
      Space bottom = 30
    */
    let height = viewport.height - 277.76;
    /*
    PHONE HEIGHT CALCULATE = 241.84 = 91.84 + 70 + 50 + 30
      Title Header Height = 91.84 (height=32, padding-top=10, padding-bottom=10, margin-top=19.920, margin-bottom=19.920)
      Space bottom = 20
    */
    height =
      viewport.type === ResponsiveType.DESKTOP
        ? height
        : viewport.type === ResponsiveType.TABLET
        ? height - 60
        : height - 25.92;
    height = height < viewport.height / 2 ? viewport.height - 200 : height;
    /*
    DESKTOP WIDTH CALCULATE
      Open menu width = 300
      Closed menu width = 100
      Container padding = 100 (padding-right=50 padding-left=50)
    */
    let width = viewport.width - (isMenuOpen ? 300 : 100) - 100;
    /*
    PHONE WIDTH CALCULATE
      Menu width = 0
      Container padding = 20 (padding-right=10 padding-left=10)
    */
    width = this.width =
      viewport.type !== ResponsiveType.DESKTOP ? viewport.width - 20 : width;
    // WIDTH COLUMN MAX = 365
    let widthColumn = (this.columnWidth = width - 30 > 365 ? 255 : width - 30);
    let widthEpgHeader =
      width - 30 > 365
        ? viewport.type !== ResponsiveType.DESKTOP
          ? width - 40
          : width
        : widthColumn - 5;
    const direction = getFromLocal("layoutDirection");
    return (
      <>
        {modalNPVRInfo?.showModalNPVR && (
          <ModalNPVRRecord refreshPrograms={this.refreshPrograms.bind(this)} />
        )}
        <>
          <div
            className={classnames(styles.container, {
              [styles[direction.toUpperCase()]]: true,
            })}
          >
            <Header>
              <Trans t={t}>{this.props.route.title}</Trans>
            </Header>
            <>
              <div
                className={styles.headerSelector}
                style={{ width: widthEpgHeader }}
              >
                {sortedGroups && sortedGroups.length > 0 && (
                  <EpgHeaderSelector
                    refDate={EPGDate}
                    onChange={this.updateEPGTime.bind(this)}
                    handleLeftArrow={() => this.handleArrow("left")}
                    handleRightArrow={() => this.handleArrow("right")}
                    groups={sortedGroups}
                    viewport={viewport}
                    handleApplyFilter={this.handleApplyFilter.bind(this)}
                    handleApplySort={this.handleApplySort.bind(this)}
                  />
                )}
              </div>
              {menuContextOpen === true && (
                <div
                  ref={this.menuContextRef}
                  style={{
                    top: menuContext.clientY + 30,
                    width: widthColumn - 40,
                    left: menuContext.clientX,
                    zIndex: 10,
                    display: menuContextOpen ? "block" : "none",
                    right: direction.toLowerCase() === "rtl" ? "unset" : "0px",
                  }}
                  className={styles.menuContext}
                >
                  <div className={styles.buttonContainer}>
                    {this.isChannelFavorite(menuContext.channel.getId()) ? (
                      <FavoriteRemove
                        className={styles.addFavIcon}
                        onClick={() =>
                          this.AddToFavorite(menuContext.channel.getId())
                        }
                      />
                    ) : (
                      <FavoriteAdd
                        className={styles.addFavIcon}
                        onClick={() =>
                          this.AddToFavorite(menuContext.channel.getId())
                        }
                      />
                    )}
                    <div
                      className={styles.text}
                      onClick={() =>
                        this.AddToFavorite(menuContext.channel.getId())
                      }
                    >
                      {this.isChannelFavorite(menuContext.channel.getId()) ? (
                        <Trans t={t}>Remove from favorite</Trans>
                      ) : (
                        <Trans t={t}>Add to favorite</Trans>
                      )}
                    </div>
                  </div>
                  <Link
                    to={consts.routes.playerlive.url.replace(
                      ":channelId",
                      menuContext.channel.getId()
                    )}
                    className={styles.buttonContainer}
                  >
                    <Live className={styles.watchLiveIcon} />
                    <div
                      to={consts.routes.playerlive.url.replace(
                        ":channelId",
                        menuContext.channel.getId()
                      )}
                      className={styles.text}
                    >
                      <Trans t={t}>Watch live</Trans>
                    </div>
                  </Link>
                </div>
              )}
              {isEPGLoading && (
                <Loader
                  loaderMinHeightClassName={styles.loader}
                  centered={true}
                />
              )}
              {!isEPGLoading && (
                <div
                  className={styles.content}
                  style={{ width: width }}
                  ref={this.bodyGrid}
                  onMouseDown={(mouseDownEvent) =>
                    this.handleMouseDown(mouseDownEvent)
                  }
                  onMouseMove={(mouseMoveEvent) =>
                    this.handleMouseMove(mouseMoveEvent)
                  }
                  onMouseUp={() => this.handleMouseUp()}
                  onScroll={this.handleScroll}
                >
                  {items?.length > 0 &&
                    items.map((channel, index) => {
                      if (this.state.cache[channel.id]) {
                        return (
                          <div key={index}>
                            <div
                              key={`chanI_${index}`}
                              style={{ position: "relative" }}
                              ref={(ch) => {
                                channelRef[channel.name] = ch;
                              }}
                            >
                              <EPGHeaderItem
                                channel={channel}
                                fta={channel.fta}
                                isConnected={this.props.isConnected}
                                subscriptionModal={this.subscriptionModal.bind(
                                  this
                                )}
                                openMenuContext={this.openMenuContext.bind(
                                  this
                                )}
                                isFavorite={this.isChannelFavorite(
                                  channel.getId()
                                )}
                              />
                            </div>
                            <AnimatedList
                              className={styles.contentColumn}
                              duration={300}
                              deferredMeasurementCache={
                                this.state.cache[channel.id]
                              }
                              rowRenderer={this.renderRow.bind(this, channel)}
                              height={height}
                              scrollToRow={this.findEPGScrollPosition(channel)}
                              scrollToAlignment={"start"}
                              rowCount={channel.getEPGs().length + 1}
                              rowHeight={this.state.cache[channel.id].rowHeight}
                              overscanRowCount={10}
                              width={widthColumn}
                              ref={(el) => {
                                this.setRef(el, channel.id);
                              }}
                            />
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                </div>
              )}
            </>
          </div>
        </>
      </>
    );
  }
}

export default compose(
  connect((state, props) => {
    const EPGDate = state.ui.EPGDate;
    const channelFavorits = state.content.favoritesChannels.data;
    let filter = state.ui.discoverFilter;
    const profileToken = state.session.profileToken || null;
    let sort = state.ui.discoverSort;
    if (props.isConnected && profileToken) {
      const decoded = jwt_decode(profileToken);
      const channelFilter = state.ui.channelFilter;
      const sortValue = state.ui.channelSort;
      // object that has profile and filter for the given profile
      const profileFilter =
        channelFilter?.filter((item) => item.profile === decoded?.id_profile) ||
        null;
      const profileSort =
        sortValue?.filter((item) => item.profile === decoded?.id_profile) ||
        null;
      filter = (profileFilter?.length > 0 && profileFilter[0]?.value) || null;
      sort = (profileSort?.length > 0 && profileSort[0]?.value) || null;
    }
    const directArrayValueForFavorites = state.ui.directArrayForFavorites;
    return {
      channelFavorits,
      isConnected: state.session.customerAuthToken !== undefined,
      profileToken: profileToken,
      isEPGLoading: state.content.epg.loading,
      viewport: state.ui.viewport,
      showLogin: state.ui.loginShown,
      modalNPVRInfo: state.ui.modalNPVRInfo,
      EPGDate: EPGDate,
      channels: TucanoSelectors.getEPG(
        state,
        EPGDate,
        consts.durationEPGDisplayImage
      ),
      groups: TucanoSelectors.getGroups(state, channelFavorits),
      filter: filter,
      sortValue: sort,
      filteredChannels: TucanoSelectors.getFilteredEpg(
        state,
        EPGDate,
        filter,
        sort,
        "",
        directArrayValueForFavorites,
        channelFavorits,
        consts.durationEPGDisplayImage
      ),
    };
  }),
  withTranslation()
)(withRouter(PageEPG));
